.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 90px);
}

.loader {
    margin: 0 auto 15px auto;
    width: 50px;
    height: 60px;
    text-align: center;
}

.loader > div {
    background-color: #D76D00;
    height: 100%;
    width: 6px;
    margin-right: 2px;
    display: inline-block;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.loader .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.loader .rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.loader .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.loader .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4);
    }
    20% {
        -webkit-transform: scaleY(1);
    }
}

@keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1);
    }
}
