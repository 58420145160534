@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* SCROLL BAR CUSTOMIZATION STYLES */
  ::-webkit-scrollbar {
    background: none;
    width: 0;
  }

  ::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 6px;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .custom-input-date::-webkit-calendar-picker-indicator {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
  }

  [x-cloak] {
    display: none !important;
  }
}
